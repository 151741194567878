import { createContext } from 'react';

const initialState = {
  editor_expanded: true,
  show_chat_widget: false,
  plan_name: "no_plan",
  plan: null,
  other_plans_name: [],
  other_plans: [],
  loading: true,  // Add loading state
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, ...action.payload };
    case 'LOAD_DATA':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const isFeatureAvailable = (state, action) => {
  const { plan_name, plan, other_plans_name } = state;

  //console.log(plan_name);

  if (action === "tts_subtitle") {
    const isPremiumPlan = plan_name && ["voiceover - premium", "voiceover - pro"].includes(plan_name);
    const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Standard", "AppSumo-Premium", "AppSumo-Pro"].includes(plan));

    return isPremiumPlan;
  }
  else if(action === "tts_bgm") {
    const isPremiumPlan = plan_name && ["voiceover - standard", "voiceover - premium", "voiceover - pro"].includes(plan_name);
    const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Standard", "AppSumo-Premium", "AppSumo-Pro", "AppSumo-Ultra"].includes(plan));

    return (isPremiumPlan || isAppSumoPlan);
  }
  else if (action === "stt_speaker_detection") {
    const isPremiumPlan = plan_name && ["dubbing - standard", "dubbing - premium", "dubbing - pro"].includes(plan_name);
    const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Standard", "AppSumo-Premium", "AppSumo-Pro", "AppSumo-Ultra"].includes(plan));

    return (isPremiumPlan || isAppSumoPlan);
  }
  else if (action === "snd_effect") {
    const isPremiumPlan = plan_name && ["voiceover - premium", "voiceover - pro"].includes(plan_name);
    const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Premium", "AppSumo-Pro", "AppSumo-Ultra"].includes(plan));

    return (isPremiumPlan || isAppSumoPlan);
  }
  else if (action === "snd_effect_upload") {
    const isPremiumPlan = plan_name && ["voiceover - premium", "voiceover - pro"].includes(plan_name);
    const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Pro", "AppSumo-Ultra"].includes(plan));

    return (isPremiumPlan || isAppSumoPlan);
  }
  else if (action === "scriptbot") {
    return true;
  }

  return false;
};


const isVoiceAccessEnabled = (state, voiceObject) => {
    const { plan_name, plan, other_plans_name } = state;
    // console.log(plan_name);
    // console.log(other_plans_name);
    if(voiceObject.is_multilingual && (voiceObject.batch === 1 || voiceObject.batch === 2 || voiceObject.batch === 3)) {
        const isPremiumPlan = plan_name && ["voiceover - standard", "voiceover - premium", "voiceover - pro"].includes(plan_name);
        const isAppSumoPlan = other_plans_name.some(plan => ["AppSumo-Premium", "AppSumo-Pro", "AppSumo-Ultra"].includes(plan));

        return (isPremiumPlan || isAppSumoPlan);
    }
    return true;
}


const isImageEngineAccessEnabled = (state, engine) => {
  const { plan_name, plan, other_plans_name } = state;
  // console.log(plan_name);
  // console.log(other_plans_name);
  const enabled = plan_name && [
    "voiceover - standard", "voiceover - premium", "voiceover - pro",
    "writer - standard", "writer - premium", "writer - pro"
  ].includes(plan_name);
  if(engine === "premium" || engine === "ultra") {
    return enabled;
  }
  return true;
}


const isSubscribed = (state) => {
    return state.plan_name && state.plan_name != "no_plan";
}


const StoreContext = createContext();

export { initialState, reducer, isFeatureAvailable, isVoiceAccessEnabled, isImageEngineAccessEnabled, isSubscribed, StoreContext };
